/** Get list by tenant action **/
export class GetUsersListByTenant {
  static readonly type = '[Users] GetUsersListByTenant';
  constructor() {}
}

/** Get from all user tenants action **/
export class GetUsersListFromAllUserTenants {
  static readonly type = '[Users] GetUsersListFromAllUserTenants';
  constructor() {}
}

/** Get list by tenant action **/
export class GetAttachedUsersList {
  static readonly type = '[Users] GetAttachedUsersList';
  constructor(public payload) {}
}

/** update avatar url of a user **/
export class UpdateUserAvatar {
  static readonly type = '[Users] UpdateUserAvatar';
  constructor(public payload) {}
}

/** update a user **/
export class UpdateUser {
  static readonly type = '[Users] Update';
  constructor(public payload) {}
}

/** activate a user **/
export class ActivateUsers {
  static readonly type = '[Users] Activate';
  constructor(
    public payload: {
      userIds: string[];
    },
  ) {}
}

/** deactivate a user **/
export class DeactivateUsers {
  static readonly type = '[Users] Deactivate';
  constructor(
    public payload: {
      usersToDeactivate: string[];
      userToTransferAccessTo?: string;
    },
  ) {}
}

/** update user's theme **/
export class UpdateTheme {
  static readonly type = '[Users] Update theme';
  constructor(public payload) {}
}

export class UserSetAvatarImageUploadLoading {
  static readonly type = '[Users] Set User Avatar Image upload Loading';
  constructor(public payload) {}
}

/** update user's status (received via socket) **/
export class UpdateUserStatus {
  static readonly type = '[Users] Update User Status';
  constructor(public payload) {}
}

/** get bulk user info (ids are gathered then sent in pack) **/
export class QueueGetBulkUsersInfo {
  static readonly type = '[Users] Queue get bulk users info';
  constructor(public payload) {}
}

/** get bulk user info final action **/
export class GetBulkUsersInfo {
  static readonly type = '[Users] Get bulk users info';
  constructor(public payload) {}
}

/** get user info paginated by cursor **/
export class GetUsersByCursor {
  static readonly type = '[Users] Get users info by cursor';
  constructor() {}
}

/** get user info paginated by cursor **/
export class PromoteUserToAdmin {
  static readonly type = '[Users] Promote To Admin';
  constructor(
    public payload: {
      userId: string;
    },
  ) {}
}

/** update search filter **/
export class UpdateUsersSearch {
  static readonly type = '[Users] Update users search';
  constructor(
    public payload: {
      search?: string | undefined;
    },
  ) {}
}

/** change user role action **/
export class ChangeUserRole {
  static readonly type = '[Users] Change user role';
  constructor(public payload) {}
}

/** update users data after change some user **/
export class UpdateUsersAfterChange {
  static readonly type = '[Users] Update after change';
  constructor(public payload) {}
}

/** delete user action **/
export class DeleteUserAccount {
  static readonly type = '[Users] Delete user account';
  constructor(public payload) {}
}

/** Transfer user permission action **/
export class TransferUserPermission {
  static readonly type = '[Users] Transfer user permission';
  constructor(public payload) {}
}

export class UsersEmojiPicker {
  static readonly type = '[Users] EmojiPicker';
  constructor(public payload) {}
}

export class UsersSetEmoji {
  static readonly type = '[Users] SetEmoji';
  constructor(public payload) {}
}

export class UsersNewAccount {
  static readonly type = '[Users] NewUser';
  constructor(
    public payload: {
      userId: string;
      userName: string;
      avatarUrl: string;
      status: string;
      email: string;
      isActive: boolean;
      isDeleted: boolean;
      tenantName: string;
      createdAt: string;
      timezone: string;
    },
  ) {}
}
